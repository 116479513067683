import React from 'react';
import { servicesContent } from '../../content/index';

const Hero = () => {
  const { header, description } = servicesContent.hero;

  return (
    <div data-testid="heroContainer" className="max-w-530px lg:max-w-580px">
      <h2
        data-testid="heroHeader"
        className="font-bold text-2xl tracking-tighter mb-5 md:mb-6 md:font-extrabold md:text-4xl md:leading-48px lg:tracking-normal"
      >
        {header}
      </h2>
      <p
        data-testid="heroDescription"
        className="font-normal text-base tracking-tighter lg:text-lg lg:tracking-normal"
      >
        {description}
      </p>
    </div>
  );
};

export default Hero;
