import React from 'react';
import { ServicesCardData } from '../../types/content/services';
import appDeveloper from '../../../static/services/services/appDevelopment.svg';
import contentCreation from '../../../static/services/services/contentCreation.svg';
import digitalStrategy from '../../../static/services/services/digitalStrategy.svg';
import adobeTechnologies from '../../../static/services/services/adobeTechnologies.svg';
import adobeTechnologiesOrange from '../../../static/services/services/adobeTechnologiesOrange.svg';
import streamingOTT from '../../../static/services/services/streamingOTT.svg';
import webDev from '../../../static/services/services/webDevelopment.svg';
import appDeveloperOrange from '../../../static/services/services/appDevelopmentOrange.svg';
import contentCreationOrange from '../../../static/services/services/contentCreationOrange.svg';
import digitalStrategyOrange from '../../../static/services/services/digitalStrategyOrange.svg';
import streamingOTTOrange from '../../../static/services/services/streamingOTTOrange.svg';
import webDevOrange from '../../../static/services/services/webDevelopmentOrange.svg';

type BodyPropsType = {
  cardModel: ServicesCardData;
  isIconOnly: boolean;
  serviceIconClassName: string;
};

const ServiceIcon = ({
  cardModel,
  isIconOnly,
  serviceIconClassName,
}: BodyPropsType) => {
  const imageDictionaryMap = new Map([
    ['appDevelopment.svg', appDeveloper],
    ['contentCreation.svg', contentCreation],
    ['digitalStrategy.svg', digitalStrategy],
    ['streamingOTT.svg', streamingOTT],
    ['webDevelopment.svg', webDev],
    ['adobeTechnologies.svg', adobeTechnologies],
  ]);

  const imageHoverDictionaryMap = new Map([
    ['appDevelopmentOrange.svg', appDeveloperOrange],
    ['contentCreationOrange.svg', contentCreationOrange],
    ['digitalStrategyOrange.svg', digitalStrategyOrange],
    ['streamingOTTOrange.svg', streamingOTTOrange],
    ['webDevelopmentOrange.svg', webDevOrange],
    ['adobeTechnologiesOrange.svg', adobeTechnologiesOrange],
  ]);

  const iconClassName = serviceIconClassName;

  const handleTileClick = (id: string) => (event: any) => {
    event.preventDefault();
    const tag = id.startsWith('#') ? id.substr(1) : id;
    const selector = document.getElementById(tag);
    if (selector) {
      selector.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return !isIconOnly ? (
    <div className="flex justify-center">
      <button
        data-testid="iconItem"
        onClick={handleTileClick(cardModel.link.split('#')[1])}
        className="group transition-all gap-2.5 md:gap-4"
      >
        <div className="flex justify-center">
          <div className="absolute md:h-8 duration-300 pointer-fine:group-hover:opacity-0">
            <img
              className={iconClassName}
              src={imageDictionaryMap.get(cardModel.image.file)}
              alt={cardModel.image.alt}
            />
          </div>
          <div className="relative md:h-8 duration-300 opacity-0 pointer-fine:group-hover:opacity-100">
            <img
              className={iconClassName}
              src={imageHoverDictionaryMap.get(cardModel.image.fileHover)}
              alt={cardModel.image.alt}
            />
          </div>
        </div>
        <div className="mt-2 text-center self-center">
          <div className="inline text-12px md:text-17px tracking-tighter font-semibold uppercase xl:text-base pointer-fine:group-hover:text-secondary duration-300">
            {cardModel.title}
          </div>
        </div>
      </button>
    </div>
  ) : (
    <img
      className={iconClassName}
      src={imageDictionaryMap.get(cardModel.image.file)}
      alt={cardModel.image.alt}
    />
  );
};

export default ServiceIcon;
