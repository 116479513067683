import * as React from 'react';
import DocumentHead from '../../components/DocumentHead';
import Hero from '../../components/services/Hero';
import Services from '../../components/services/Services';
import Company from '../../components/services/Company';
import PageSection from '../../components/PageSection';
import content from '../../content/services.yaml';

const ServicesPage = () => {
  return (
    <div data-testid="services-page-container" className="md-900px:pt-9 pt-7">
      <DocumentHead content={content.metadata} />
      <PageSection
        testId="hero-container"
        className="my-7 md:mt-10 md:mb-10 lg:mt-9 lg:mb-11 mx-3 lg:mx-68px md:mx-64px"
      >
        <Hero />
      </PageSection>

      <PageSection
        testId="services-container"
        className="mx-3 lg:mx-68px md:mx-64px"
      >
        <Services />
      </PageSection>

      <PageSection
        testId="company-container"
        className="mt-10 mb-10 lg:mt-12 lg:mb-12 mx-3 lg:mx-68px md:mx-64px"
      >
        <Company />
      </PageSection>
    </div>
  );
};

export default ServicesPage;
