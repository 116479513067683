import React from 'react';
import Button from '../Button';
import { servicesContent } from '../../content/index';

const Company = () => {
  const { header, description, cta } = servicesContent.company;

  return (
    <div data-testid="companyContainer" className="mx-auto w-full block">
      <h2
        data-testid="companyHeader"
        className="font-bold md:font-extrabold text-19px mx-auto text-center leading-6 md:leading-9 mb-3 md:mb-6  md:text-28px lg:text-32px lg:leading-10"
      >
        {header.line1} <br />
        {header.line2}
      </h2>
      <p
        data-testid="companyDescription"
        className="font-normal text-sm w-64% text-center mx-auto lg:text-base lg:w-375px"
      >
        {description}
      </p>
      <Button
        className="hidden md:block mx-auto mt-7"
        type="inverse"
        text={cta.desktop}
        to="/contact"
      />
      <Button
        className="block md:hidden mx-auto mt-7"
        type="inverse"
        to="/contact"
        text={cta.mobile}
      />
    </div>
  );
};

export default Company;
