import React from 'react';
import blackArrow from '@assets/arrow-black.svg';
import { Link } from 'gatsby';
import classNames from 'classnames';
import ServiceIcon from './ServiceIcon';
import { servicesContent } from '../../content/index';
import Button from '../Button';
import ContentImageTile from '../ContentImageTile';
import ScrollTag from '../ScrollTag';

const Services = () => {
  const { ctas, cards } = servicesContent.services;
  const displayPortfolioImage: Number[] = [1, 3];
  const displayPortfolioLine: Number[] = [0, 2, 4];
  const serviceIconClassName = 'h-48px md:h-70px';

  return (
    <div data-testid="servicesContainer">
      <div
        className={classNames(
          'grid grid-cols-2 justify-around gap-y-6 mb-11',
          'md:grid-cols-3 md:mb-12 md:gap-y-12 xl:flex',
        )}
      >
        {cards.map(card => (
          <ServiceIcon
            serviceIconClassName={serviceIconClassName}
            cardModel={card}
            isIconOnly={false}
            key={`${card.title} icon item`}
          />
        ))}
      </div>
      <hr
        data-testid="horizontal-line"
        className="h-1px border-none bg-black mt-7 md:mt-10"
      />
      <div className="flex flex-col">
        {cards.map((card, cardIndex) => (
          <div data-testid="serviceCard" key={`${card.title} card`}>
            <div className={classNames('rounded', 'md:px-10 lg:px-0')}>
              <ScrollTag id={card.link.split('#')[1]} />
              <div className="flex flex-row lg:ml-12 xl:ml-7">
                <div className="hidden xl:block h-8 w-12 mt-10">
                  <ServiceIcon
                    serviceIconClassName={serviceIconClassName}
                    cardModel={card}
                    isIconOnly
                  />
                </div>
                <div className="flex-1 pt-7 md:pt-10 lg:pr-12">
                  <h2
                    className={classNames(
                      'font-bold tracking-tighter text-base',
                      'md:font-extrabold md:text-28px',
                      'lg:font-extrabold lg:text-32px lg:tracking-normal',
                    )}
                  >
                    {card.title}
                  </h2>
                  {card.icons && (
                    <div className="flex gap-x-4 md:gap-x-7 my-5 md:my-6">
                      {card.icons.map(icon => (
                        <img
                          key={`${icon.alt} icon`}
                          src={`../../services/services/${icon.file}`}
                          alt={icon.alt}
                          className="h-15px xs:h-22px md:h-5 lg:h-6 object-contain"
                        />
                      ))}
                    </div>
                  )}
                  {!card.icons && <div className="pb-5 md:pb-7" />}
                  <p
                    className={classNames(
                      'text-sm font-normal tracking-tighter',
                      'lg:tracking-normal lg:max-w-55vw lg:text-base',
                    )}
                  >
                    {card.description}
                  </p>
                  <div className="lg:flex">
                    <div className="hidden md:flex gap-x-3 mt-7">
                      <Button text={ctas.profile.desktop} to="/portfolio" />
                      <Button text={ctas.contact.desktop} to="/contact" />
                    </div>
                    <div className="hidden sm:flex md:hidden gap-x-3 mt-7">
                      <Button text={ctas.profile.mobile} to="/portfolio" />
                      <Button text={ctas.contact.mobile} to="/contact" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-4 justify-evenly">
              {card.portfolio.map(
                (portfolioElement, portfolioItemIndex) =>
                  displayPortfolioImage.includes(cardIndex) && (
                    <Link
                      data-testid="portfolioItem"
                      key={`${card.title} ${portfolioElement.title}`}
                      to="/portfolio"
                      className={classNames(
                        'flex-1 mt-5 md:mt-10',
                        portfolioItemIndex !== 0 && 'hidden md:block',
                      )}
                    >
                      <div
                        className="w-full pb-56.24% relative h-0 overflow-hidden"
                        data-testid="contain"
                      >
                        <ContentImageTile
                          isAnimated
                          imagePath={`../../services/services/${portfolioElement.image.file}`}
                          logoPath={`../../services/services/${portfolioElement.logo.file}`}
                          alt={portfolioElement.image.alt}
                          className="shadow-darker-md"
                          hoverCta={ctas.profile.desktop}
                          imageLoading="eager"
                        />
                      </div>
                      <div className="md:hidden text-right decoration-1 text-16px mt-2">
                        {ctas.profile.mobile}
                        <img
                          className="inline ml-1 md:hidden"
                          alt=""
                          src={blackArrow}
                        />
                      </div>
                    </Link>
                  ),
              )}
            </div>
            {displayPortfolioLine.includes(cardIndex) && (
              <hr
                data-testid="horizontal-line"
                className="h-1px border-none bg-black mt-7 md:mt-10"
              />
            )}
          </div>
        ))}
      </div>
      <hr
        data-testid="horizontal-line"
        className="h-1px border-none bg-black mt-7 md:mt-10"
      />
    </div>
  );
};

export default Services;
